// ---------- mobile vh fix ----------
import mobileVh from './Components/mobileVh'
mobileVh()

//load the regional map States demo as needed
let regionalMapDemoEls = document.querySelectorAll('[data-regional-map]')
if (regionalMapDemoEls.length) {
	import(/* webpackChunkName: "demoRegionalMap" */ './Demos/RegionalMapDemo').then(({ default: regionalMapDemo }) => {
		regionalMapDemo.init()
	})
}

//load the regional map AZ demo as needed
let regionalMapAzSelector = '[data-regional-map-az]'
let $regionalMapDemoAzEls = document.querySelectorAll(regionalMapAzSelector)
if ($regionalMapDemoAzEls.length) {
	import(/* webpackChunkName: "demoRegionalMapAz" */ './Demos/RegionalMapDemoAz').then(
		({ default: regionalMapDemo2 }) => {
			regionalMapDemo2.init({ selector: regionalMapAzSelector })
		},
	)
}

//load the block map demo as needed
let blockMapDemoEls = document.querySelectorAll('[data-block-map]')
if (blockMapDemoEls.length) {
	import(/* webpackChunkName: "demoBlockMap" */ './Demos/BlockMapDemo').then(({ default: blockMapDemo }) => {
		blockMapDemo.init()
	})
}

//load the floating map demo as needed
let floatingMapDemoEls = document.querySelectorAll('[data-floating-map]')
if (floatingMapDemoEls.length) {
	import(/* webpackChunkName: "demoFloatingMap" */ './Demos/FloatingMapDemo').then(({ default: floatingMapDemo }) => {
		floatingMapDemo.init()
	})
}

//load the routing map demo as needed
let routingMapDemoEls = document.querySelectorAll('[data-routing-map]')
if (routingMapDemoEls.length) {
	import(/* webpackChunkName: "demoRoutingMap" */ './Demos/RoutingMapDemo').then(({ default: routingMapDemo }) => {
		routingMapDemo.init()
	})
}
